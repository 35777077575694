import { Auth } from "aws-amplify";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "components/user";
import { Dropdown } from "components/dropdown";
import { Text } from "components/text";
import { PageLayout, Wrapper } from "components/layout";
import { UserNeedsAuthentication } from "components/user";
import { config, CartaFile } from "library/api";

const fetchProfiles = async (): Promise<CartaFile[]> => {
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const host = (await config).host;
  const url = new URL(`${host}/files/Carta/list`);
  url.searchParams.append("path", ":/carta-collections/profiling/");
  return fetch(url, {
    method: "GET",
    headers: { Authorization: `Bearer ${idToken}` },
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(`An unexpected API error occurred. 
            Request: 'GET ${url}'. 
            Status code:  '${response.status}'.`);
      }
      return response;
    })
    .then((response) => response.json())
    .then((response) => {
      return response as CartaFile[];
    });
};

// Currently the analysis page has a single dropdown from which pre-calculated
// dataset profiling results (done on the backend by a standard python library
// which generates a HTML profiling report per dataset) can be selected for
// display. We may add other types of pre-calculated results in future, and/or
// change the layout of this page.

const AnalysisAuthenticated: FunctionComponent = () => {
  const [error, setError] = useState<string>();
  const [profiles, setProfiles] = useState<CartaFile[]>([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        let listing = await fetchProfiles();
        listing = listing.filter(
          (item) =>
            item.path.startsWith("/carta-collections/profiling/") &&
            item.path.endsWith(".html")
        );
        setProfiles(listing);
      } catch (error) {
        if (error instanceof Error) setError(error.message);
        else setError("An unknown error occurred.");
      }
    })();
  }, []);

  const handleSelect = (key: string) => {
    history.push(`/html?key=${key}`);
  };

  return (
    <div>
      <Dropdown side="bottom-left">
        <Dropdown.Toggler caret>
          <Text size="medium">Profile</Text>
        </Dropdown.Toggler>
        <Dropdown.Area>
          {profiles.map((item: CartaFile) => (
            <Dropdown.Item
              key={item.id}
              onClick={() => handleSelect(`${item.id}`)}
            >
              {item.path.substring(29, item.path.lastIndexOf("."))}
            </Dropdown.Item>
          ))}
        </Dropdown.Area>
      </Dropdown>
      <Text color="error" size="small" padding="center">
        {error}
      </Text>
    </div>
  );
};

const AnalysisPage: FunctionComponent = () => {
  const { authenticated } = useContext(UserContext);

  return (
    <PageLayout header footer>
      <Wrapper>
        {authenticated ? (
          <AnalysisAuthenticated />
        ) : (
          <UserNeedsAuthentication />
        )}
      </Wrapper>
    </PageLayout>
  );
};

export default AnalysisPage;
