import { BaseSyntheticEvent, FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { withStyles } from "@mui/styles";
import { Text } from "components/text";
import TreeNode from "./TreeNode";
import { INode } from "data/types";
import useCartaObjectStorage from "hooks/useCartaObjectStorage";

interface IProjectNodeProps {
  connectionId: string;
  node: INode;
  projectId?: string;
  forms?: { title?: string; key: string }[];
}

const TreeViewComponent: FunctionComponent<IProjectNodeProps> = ({
  connectionId,
  node,
  projectId,
  forms,
}) => {
  const [error, setError] = useState<string>();
  const [tree, setTree] = useState<TreeNode>(new TreeNode(node));
  const [expanded, setExpanded] = useState<string[]>([]);
  const history = useHistory();
  const cartaObjectStorage = useCartaObjectStorage()

  const handleSelect = async (event: BaseSyntheticEvent, nodeId: string) => {
    const projectNode = tree.search(nodeId);
    if (projectNode && projectNode.node.type === "leaf") {
      let url = `/collection?projectId=${projectId}&connectionId=${connectionId}&nativeId=${nodeId}`;
      if (projectNode.parentId)
        url = `${url}&parentNativeId=${projectNode.parentId}`;
      history.push(url);
    } else if (projectNode && !projectNode.children.length) {
      try{
        const nodesList = await (await cartaObjectStorage).listNodes({connectionId}, projectNode.node);
        let nodes = nodesList.nodes;
      
        nodes = nodes.filter((n) => n.name !== "_carta_file_upload");
        nodes.sort((a, b) => a.name.localeCompare(b.name));
        setTree(tree.addChildren(nodes, nodeId));
      }catch(err){
        if (err instanceof Error) setError(err.message);
        else setError("An unknown error occurred.");
      }finally{
        setExpanded([...expanded, nodeId])
      }
    }
  };

  const handleToggle = (event: BaseSyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const nodeLabel = (tree: TreeNode): string => {
    if (tree.node.type === "leaf") {
      if (forms) {
        return forms.find(({ key }) => key === tree.node.name)?.title || "";
      }
      return "";
    }
    return tree.node.name;
  };

  const FileTreeItem = withStyles({
    root: {
      "&.MuiTreeItem-root > .MuiTreeItem-content:hover": {
        background: "var(--color-primary-hover)",
        color: "var(--color-primary)",
      },
      "&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label": {
        background: "var(--color-primary-hover)",
        color: "var(--color-primary)",
      },
    },
  })(TreeItem);
  const FolderTreeItem = withStyles({
    root: {
      "&.MuiTreeItem-root > .MuiTreeItem-content:hover": {
        background: "var(--color-primary-hover)",
      },
      "&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label": {
        background: "var(--color-primary-hover)",
      },
    },
  })(TreeItem);

  const createTreeItems = (tree: TreeNode) => {
    if (
      tree.node.type === "leaf" &&
      tree.node.name !== "empty" &&
      forms &&
      !forms.find(({ key }) => key === tree.node.name)
    ) {
      // Files that are not forms should not be added to the tree
      return <></>;
    }
    if (tree.children.length) {
      if (tree.node.type === "leaf")
        return (
          <FileTreeItem
            key={tree.id}
            nodeId={tree.id}
            label={nodeLabel(tree)}
            endIcon={<FileOpenIcon />}
          >
            {tree.children.length > 0 &&
              tree.children.map((child) => createTreeItems(child))}
          </FileTreeItem>
        );
      else
        return (
          <FolderTreeItem
            key={tree.id}
            nodeId={tree.id}
            label={nodeLabel(tree)}
            endIcon={<ChevronRightIcon />}
          >
            {tree.children.length > 0 &&
              tree.children.map((child) => createTreeItems(child))}
          </FolderTreeItem>
        );
    } else {
      if (tree.node.type === "leaf")
        return (
          <FileTreeItem
            key={tree.id}
            nodeId={tree.id}
            label={nodeLabel(tree)}
            endIcon={<FileOpenIcon />}
          />
        );
      else if (tree.node.type === "empty")
        return (
          <FileTreeItem
            key={tree.id}
            nodeId={tree.id}
            label="_empty_"
            disabled
            endIcon={<div></div>}
          />
        );
      else
        return (
          <FolderTreeItem
            key={tree.id}
            nodeId={tree.id}
            label={nodeLabel(tree)}
            endIcon={<ChevronRightIcon />}
          />
        );
    }
  };

  return (
    <>
      <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        expanded={expanded}
      >
        {createTreeItems(tree)}
      </TreeView>
      <Text color="error" size="small" padding="center">
        {error}
      </Text>
    </>
  );
};

export default TreeViewComponent;
export type { IProjectNodeProps };
